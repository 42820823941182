import { useReactiveVar } from '@apollo/client';
import { BreadcrumbItem } from '@goed-platform/breadcrumb/type';
import { Breadcrumbs } from '@goed-platform/breadcrumb/ui';
import { CartIndicator } from '@goed-platform/cart/ui';
import { StoreServiceType, UserInfo } from '@goed-platform/graphql/types';
import { ImageLayoutEnum, ImageObjectfitEnum } from '@goed-platform/product/enums';
import { Config, nextI18nextConfig } from '@goed-platform/shared/constants';
import {
    cartVarRes,
    userInfoVar,
    webResActivePharmacy,
    WebreservationPharmacy,
} from '@goed-platform/shared/data-access';
import { CustomAppProps, RoutesEnum } from '@goed-platform/shared/types';
import { BaseLayout, Button, ButtonSizeEnum, ButtonTypeEnum, OptimizedImage, SEOTags } from '@goed-platform/shared/ui';
import {
    getPharmacyCurrentOpenState,
    SVGArrowRight,
    SVGPhone,
    SVGReservation,
    TranslationUtils,
} from '@goed-platform/shared/utils';
import { SymfonyDataAccess } from '@goed-platform/symfony/data-access';
import { PharmacyWithOpeningHours, SymfonyPharmacy } from '@goed-platform/symfony/types';
import { GetStaticProps } from 'next';
import { UserConfig, useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FC, useEffect, useState } from 'react';

export const getStaticProps: GetStaticProps = async ({ locale }) => {
    return {
        props: {
            base: {},
            ...(await serverSideTranslations(locale, ['common'], nextI18nextConfig as UserConfig)),
        },
        revalidate: Config.global.revalidation,
    };
};

type PageProps = CustomAppProps;

const Page: FC<PageProps> = ({ navigation }: PageProps): JSX.Element => {
    const { t } = useTranslation();
    const router = useRouter();
    const cartRes = useReactiveVar(cartVarRes);
    const user = useReactiveVar(userInfoVar);
    const activePharmacy = useReactiveVar(webResActivePharmacy);
    const [favoritePharmacies, setFavoritePharmacies] = useState([] as PharmacyWithOpeningHours[]);

    const checkMarksList = TranslationUtils.getTranslationKeyArray('general.pages.reserveer.checkmark');
    const customerStepList = TranslationUtils.getTranslationKeyArray('general.pages.reserveer.steps.customer');
    const pharmacyStepList = TranslationUtils.getTranslationKeyArray('general.pages.reserveer.steps.pharmacy');
    const faqLinks: string[] = TranslationUtils.getTranslationKeyArray('general.pages.reserveer.faq.links');
    const faqLinksLabels: string[] = faqLinks.filter((value) => value.includes('.label')) ?? [];
    const faqLinksUrls: string[] = faqLinks.filter((value) => value.includes('.url')) ?? [];

    const locationLink = `${Config.sites.main}/${router.locale}/vestigingen#service:1`;

    const breadcrumbs: BreadcrumbItem[] = [
        {
            label: t('general.breadcrumb.navigation.reservation.title'),
        },
    ];

    const renderListItem = (tKey: string, key: number): JSX.Element => (
        <li
            key={key}
            className="mb-2"
            dangerouslySetInnerHTML={{
                __html: t(tKey, {
                    vestigingLink: locationLink,
                }),
            }}
        />
    );

    const selectPharmacy = async (storeId: string): Promise<void> => {
        await SymfonyDataAccess.getInstance()
            .getPharmacyById(storeId)
            .then((data) => {
                WebreservationPharmacy.getInstance().setActivePharmacy(data as SymfonyPharmacy);
                router.push(`${RoutesEnum.webReservationProducts}`);
            });
    };

    function getUserPharmacies(user: UserInfo) {
        return user?.stores?.filter(
            (store) =>
                store.services.filter((service) => service.type === StoreServiceType.Apotheek.toLowerCase()).length
        );
    }

    useEffect(() => {
        const fetchData = async () => {
            const pharmacies = getUserPharmacies(user);

            if (!pharmacies) {
                return Promise.resolve([]);
            }

            const promises = pharmacies.map(async (pharmacy) => {
                try {
                    return SymfonyDataAccess.getInstance().getPharmacyWithHours(+pharmacy?.storeId);
                } catch (error) {
                    return error;
                }
            });

            const results = await Promise.all(promises);
            const resolvedData = results.filter((result) => !(result instanceof Error));
            setFavoritePharmacies(resolvedData);
        };
        fetchData();
    }, [user]);

    return (
        <BaseLayout navigation={navigation}>
            <SEOTags
                pageTitle={t('general.pages.reservatie.title')}
                metaDescription={t('general.pages.reservatie.lead')}
            />
            <Breadcrumbs items={breadcrumbs} />

            <div className="container pt-4 pt-lg-8 pb-sm-10">
                {/* Intro */}
                <section className="row justify-content-center mb-8">
                    <div className="col-12 col-md-8">
                        <div className="bg-neutral-white rounded-lg overflow-hidden h-fit">
                            <div className="hs-40 hs-md-80 p-0 bg-img-size-cover bg-img-no-repeat bg-img-position-center bg-dark">
                                <OptimizedImage
                                    image={{
                                        sortOrder: 0,
                                        url: 'https://wwwuat.goed.be/files/hulpmiddelen-goed-apotheek-604745310af47.jpg',
                                    }}
                                    layout={ImageLayoutEnum.fill}
                                    objectFit={ImageObjectfitEnum.cover}
                                    alt={t('reserveer')}
                                    priority={false}
                                    className="opacity-50"
                                />
                            </div>
                            <div className="hs-40 h-auto p-0 col-12 col-md-10 offset-md-1">
                                <div className="col-12 mt-n20 mt-md-n35 mt-xxl-n20">
                                    <h1 className="text-neutral-white font-size-32 font-size-lg-48 mb-4 mb-lg-6">
                                        {t('general.pages.reservatie.title')}
                                    </h1>
                                </div>
                                <div className="col-12 mt-8 mt-md-15 pb-5">
                                    <p className="lead mb-4 mb-lg-10">{t('general.pages.reservatie.lead')}</p>
                                    <ul className="list-checkmarks font-size-md-18 mb-4 mb-lg-8">
                                        {checkMarksList.map(renderListItem)}
                                    </ul>
                                    <Link href={locationLink}>
                                        <a className="unstyled">
                                            <Button
                                                type={ButtonTypeEnum.button}
                                                showArrow={true}
                                                className="w-fit mb-4"
                                                label={t('general.pages.reservatie.cta')}
                                                size={ButtonSizeEnum.medium}
                                            />
                                        </a>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* My Pharmacy */}
                {user && (
                    <section className="row justify-content-center mb-4">
                        <div className="col-12 col-md-8">
                            <div className=" col-12 col-md-10 offset-md-1">
                                <h3 className="text-primary font-size-20 font-size-lg-40 mb-4 mb-lg-6">
                                    {t('general.pages.reservatie.myPharmacy.title')}
                                </h3>
                                <hr />

                                {/* Continue shopping */}
                                {cartRes?.totalQuantity > 0 && (
                                    <div className="bg-purple-light-a30 rounded mb-5">
                                        <div className="container-lg">
                                            <div className="position-relative d-flex justify-content-between align-items-center py-4 py-md-6">
                                                <Link href={RoutesEnum.webReservationProducts}>
                                                    <a className="text-purple mr-2">
                                                        {t('general.pages.reservatie.myPharmacy.continue', {
                                                            activePharmacy: activePharmacy.name,
                                                        })}
                                                        <SVGArrowRight className="ml-1 sprite sprite-sm" />
                                                    </a>
                                                </Link>
                                                <CartIndicator
                                                    href={RoutesEnum.webReservationCart}
                                                    text={t('subheader.webreservation.cart')}
                                                    quantity={cartRes?.totalQuantity}
                                                    Icon={SVGReservation}
                                                    iconTitle="reservation"
                                                    indicatorClassName="bg-purple-x-dark"
                                                    className="text-purple mr-2 ml-md-6"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {/* Stored pharmacies */}
                                {favoritePharmacies.length > 0 ? (
                                    favoritePharmacies.map((store, key) => (
                                        <div
                                            className="bg-neutral-white mb-1 px-6 py-4 position-relative rounded mb-5"
                                            key={key}
                                        >
                                            <div className="row">
                                                <div className="bg-img-size-cover bg-img-no-repeat bg-img-position-center col-3">
                                                    <img
                                                        src="/assets/images/png/pharmacy.png"
                                                        alt="Goed"
                                                        className="logo"
                                                    />
                                                </div>
                                                <div className="h-auto p-0 col-9">
                                                    <div className="w-100 font-size-24 p-2 text-purple font-weight-bold d-flex justify-content-between align-items-center ml-2">
                                                        {store.name} •{' '}
                                                        {getPharmacyCurrentOpenState(store.services[0].opening_hours)
                                                            ? t('hours.open')
                                                            : t('hours.closed')}
                                                    </div>
                                                    {store.services?.map(
                                                        (service, skey) =>
                                                            service.type ===
                                                                StoreServiceType.Apotheek.toLowerCase() && (
                                                                <div key={skey} className="ml-2">
                                                                    {/*{store.services[0].titular && (*/}
                                                                    {/*    <div className="text-neutral-a70 d-block">*/}
                                                                    {/*        {t('webreservation.footer.titular')}{' '}*/}
                                                                    {/*        {store.services[0].titular ?? ''}*/}
                                                                    {/*    </div>*/}
                                                                    {/*)}*/}
                                                                    {service?.phone ? (
                                                                        <a
                                                                            className="d-inline-flex align-items-center"
                                                                            href={`tel:${service?.phone ?? ''}`}
                                                                        >
                                                                            <SVGPhone className="sprite sprite-md text-purple-a50 mr-1" />
                                                                            {service?.phone ?? ''}
                                                                        </a>
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                </div>
                                                            )
                                                    )}
                                                    <div className="w-100 d-flex justify-content-between align-items-center">
                                                        <div className="btn mt-3 mb-3 ml-0">
                                                            <Button
                                                                type={ButtonTypeEnum.button}
                                                                showArrow={true}
                                                                className="w-fit"
                                                                label={t(
                                                                    'general.pages.reservatie.myPharmacy.cta.label'
                                                                )}
                                                                size={ButtonSizeEnum.medium}
                                                                onClick={() => selectPharmacy(store.id.toString())}
                                                            />
                                                        </div>
                                                        <a
                                                            href={`${
                                                                store.url ??
                                                                Config.sites.main +
                                                                    '/' +
                                                                    router.locale +
                                                                    '/vestigingen/'
                                                            }#openingsuren`}
                                                            className="mr-2"
                                                            suppressHydrationWarning
                                                        >
                                                            {t('webreservation.footer.info-and-opening-hours')}
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div className="bg-neutral-white p-6 border border-neutral-a5 font-size-14 font-size-md-16 text-center rounded-lg d-flex flex-column align-items-center mb-8">
                                        <div className="text-neutral-a70 mb-2">
                                            {t('user.account.stores.empty.title')}
                                        </div>
                                        <a
                                            className="border-bottom border-purple-a25 font-weight-bold font-size-16 font-size-md-18 w-fit text-purple"
                                            role="button"
                                            href={RoutesEnum.myFavoriteStores}
                                        >
                                            {t('user.account.stores.empty.cta')}
                                            <SVGArrowRight className="ml-2 sprite sprite-md" />
                                        </a>
                                    </div>
                                )}
                            </div>
                        </div>
                    </section>
                )}

                {/* Reservation explanation */}
                <section className="row justify-content-center mb-4">
                    <div className="col-12 col-md-8">
                        <div className=" col-12 col-md-10 offset-md-1">
                            <h3 className="text-primary font-size-20 font-size-lg-40 mb-4 mb-lg-6">
                                {t('general.pages.reservatie.howItWorks.title')}
                            </h3>
                            <hr />
                            <p
                                className="font-size-md-18 mb-4 mb-md-10"
                                dangerouslySetInnerHTML={{
                                    __html: t('general.pages.reservatie.howItWorks.leadCustomer', {
                                        accountCreateLink: `/${router.locale}${RoutesEnum.register}`,
                                    }),
                                }}
                            />
                            <ol className="font-size-md-18 mb-4 mb-md-8">{customerStepList.map(renderListItem)}</ol>
                            <p className="font-size-md-18 mb-4 mb-md-10">
                                {t('general.pages.reservatie.howItWorks.leadPharmacy')}
                            </p>
                            <ol className="font-size-md-18 mb-4 mb-md-8">{pharmacyStepList.map(renderListItem)}</ol>
                        </div>
                    </div>
                </section>

                {/* CTA - find pharmacy */}
                <section className="row justify-content-center mb-4 mb-md-8">
                    <div className="col-12 col-md-8">
                        <div className=" col-12 col-md-10 offset-md-1">
                            <Link href={locationLink}>
                                <a className="unstyled">
                                    <Button
                                        type={ButtonTypeEnum.button}
                                        showArrow={true}
                                        className="w-fit"
                                        label={t('general.pages.reservatie.cta')}
                                        size={ButtonSizeEnum.medium}
                                    />
                                </a>
                            </Link>
                        </div>
                    </div>
                </section>

                {/* FAQ links */}
                <section className="row justify-content-center mb-4 mb-md-0">
                    <div className="col-12 col-md-8">
                        <div className=" col-12 col-md-10 offset-md-1">
                            <div className="bg-neutral-white rounded-lg p-4 p-lg-8">
                                <div className="h4 text-primary mb-6 font-size-20 font-size-lg-24">
                                    {t('general.pages.reserveer.faq.title')}
                                </div>
                                {faqLinksLabels.map((labelTranslation, key) => (
                                    <Link key={key} href={t(faqLinksUrls[key])}>
                                        <a
                                            className={`d-block pt-4 pb-3 font-size-lg-18 border-purple-x-light border-bottom-dashed`}
                                            rel="noreferrer"
                                        >
                                            {t(labelTranslation)}
                                            <SVGArrowRight className="ml-1 sprite sprite-sm" />
                                        </a>
                                    </Link>
                                ))}
                                <Link href={t('general.pages.reserveer.faq.watchAll.url')} passHref={true}>
                                    <a className="d-block pt-4 font-size-lg-18 border-purple-x-light w-fit">
                                        {t('general.pages.reserveer.faq.watchAll.label')}
                                    </a>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </BaseLayout>
    );
};

export default Page;
